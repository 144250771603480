import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>🍬 developersweet 🍬</h1>
        <p>
        all the tools, utilities and generators a dev could ask for!
        </p>
        <p>currently in dev by <a href="https://crowzfx.co.uk">Lui</a> (CROWZFX) and <a href="https://jarrard.xyz">James</a></p>
      </header>
    </div>
  );
}

export default App;
